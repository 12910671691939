import React from "react";
import { useRouter } from "next/router";

import { styled } from "@styles/theme";
import Logo from "@icons/illustrations/logo";

import { Flex, Box } from "../ResponsiveBox";
import Link from "../Link";
import { Text } from "../Text";
import { Spacer } from "../Spacer";
import Button from "../Button";

interface Props {
  has_login_button?: boolean;
  dark_logo?: boolean;
  logoUnredirect?: boolean;
}

export const IconName = styled.div`
  color: #fff;
  margin-right: 10px;
  background-color: ${(p) => p.theme.colors.primary["500"]};
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  margin-left: -7px;
`;

const Header: React.FC<Props> = ({
  dark_logo,
  has_login_button,
  logoUnredirect,
}) => {
  const router = useRouter();

  const fill_logo = dark_logo ? "dark" : "light";

  return (
    <Flex
      direction="row"
      justify="space-between"
      alignItems="center"
      wrap="wrap"
      width="100%"
      height={49}
    >
      <Box mB="sm">
        {router.pathname !== "/instances" && !logoUnredirect ? (
          <Link a href="/">
            <Logo fill={fill_logo} />
          </Link>
        ) : (
          <Logo fill={fill_logo} />
        )}
      </Box>

      {has_login_button && (
        <Flex direction="row" justify="flex-end" alignItems="center">
          <Box
            hide={{
              0: true,
              1: false,
            }}
          >
            <Text>Don't have an account?</Text>
          </Box>
          <Spacer size="lg" horizontal />
          <Button small color="gray" href="/account/signup">
            Sign up
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
